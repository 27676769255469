

import React, { useState, useRef,useEffect } from 'react';
import Login from './components/login';
import { Helmet } from 'react-helmet';
import Home from './components/home';

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [stepsAvailable, setStepsAvailable] = useState(0);

  // Get the URLSearchParams object from the current URL
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get the value of the 'param1' parameter
  const keyParam = urlSearchParams.get('key');
  console.log('Recieved GET param key:', keyParam);

  //we need this to store everything unique in firebase
  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');

    if (!sessionId) {
      const newSessionId = generateUniqueId();
      localStorage.setItem('sessionId', newSessionId);
    }
    console.log('Set Session ID:', sessionId);
  }, []);

  const generateUniqueId = (): string => {
    // Use a library like uuid or generate a custom ID based on your requirements
    return 'xxxxxxxx-xxxx-4xxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleAuthentication = () => {
    if (passwordRef.current) {
      const inputValue: string = passwordRef.current.value;
      if (inputValue.toLowerCase() === process.env.REACT_APP_PASSWORD) {
        setAuthenticated(true);
        setStepsAvailable(1);
        console.log('Success, Set authenticated to true');
      } else {
        alert("Helaas, dit antwoord is niet juist!");
      }
    }

  };

  return (
    <div className="w-full py-8 flex justify-center">
          <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {(keyParam === process.env.REACT_APP_GET_KEY) ? (authenticated) ? <Home /> : <Login onLoginClicked={handleAuthentication} passwordRef={passwordRef} /> : ""}
    </div>
  );
}

export default App;
