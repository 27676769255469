import React from 'react';

interface LoginProps {
    onLoginClicked: () => void;
    passwordRef: React.RefObject<HTMLInputElement>;
}

const Login: React.FC<LoginProps> = ({ onLoginClicked, passwordRef }) => {

    return (
        <header className="App-header" >
            <div className="flex w-full max-w-sm overflow-hidden bg-backgroundcolor rounded-lg shadow-md 0">
                <div className="px-4 py-4">
                    <div className="mx-3">
                        <div className="relative min-w-[200px]">
                            <input id="password" name="password" ref={passwordRef}
                                className="peer  text-slate-700  appearance-none mr-2 bg-white  font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-primary"
                                placeholder=" " />
                            <label
                                    className="flex h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-slate-700 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-primary transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                                        Geheim wachtwoord
                            </label>
                            <button onClick={onLoginClicked} className="rounded-md bg-primary w-22 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">Login!</button>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Login;


