import React from 'react';

interface privacyPopupProps {
  onClickHandler: () => void;
}


const PrivacyPopup: React.FC<privacyPopupProps> = (onClickHandler) => {
  const handleClick = () => {
    // Call the function passed from the parent
    onClickHandler.onClickHandler();
  };

  return (
    <div className="popup">
    <div className="popup-content">
      <div className="relative  transform overflow-hidden rounded-lg my-2 bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg" >
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Privacy, dat vinden wij belangrijk!</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">We vragen je naam, een wens en een foto. Deze gegevens slaan we op op en printen we uit voor een grote collage. Je gegevens worden opgeslagen in Google Firebase en we zullen ze niet verkopen of delen met anderen. Mocht je een beveilingsprobleem in deze website vinden, dan vragen we je om een responsible disclosure te delen met ons.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button onClick={handleClick} type="button" className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm  text-black shadow-sm hover:bg-secondary sm:ml-3 sm:w-auto" >ok, nevermind</button>

        </div>
      </div>
    </div>
  </div>

  );
};

export default PrivacyPopup;