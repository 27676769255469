import React from 'react';
import { BabySettings } from '../helpers/helpers';

interface Step2Props {
    onStepCompleted: () => void;
}


export const Step2: React.FC<Step2Props> = ({ onStepCompleted }) => {



    return (
        <div className="App">
                
            <p className="text-l font-bold">Wie ben je?</p>
            <p className="text-s mt-2 text-gray-600">Ik vind het leuk wanneer je een wens of groet voor mij achterlaat.</p>
            <form className="w-full max-w-lg mt-4" id="step2form">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/1 px-3 mb-2 md:mb-0">
                        <label className="block uppercase tracking-wide  text-xs font-bold mb-2" htmlFor="grid-first-name">
                            Je naam (of namen)
                        </label>
                        <input name="voornaam" className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jan" />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-1/1 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="grid-city">
                            Je wens of boodschap
                        </label>
                        <textarea name="wens"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-wish"
                            placeholder="Wat wens je Nura?"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}