import React from 'react';
import { useGlobalContext } from './globalContext';

interface Step4Props {
    onStepCompleted: () => void;
}


const Step4: React.FC<Step4Props> = ({ onStepCompleted }) => {
    const { myWish } = useGlobalContext();
    const { myName } = useGlobalContext();
    const { myPicture } = useGlobalContext();

    // Add your step 2 component logic here

    return (
        <div>
            <p className="text-l font-bold">Bedankt!</p>
            <p className="text-s mt-2 text-gray-600">Je foto en wens gaan we uitprinten en in een grote collage op de babykamer hangen! Zien we je binnenkort op visite?</p>

            <div className="flex my-4 items-center justify-center">
                <div>
                    <div className="p-4 border border-gray-300 bg-white shadow-md rounded-md transform rotate-2 hover:rotate-0 transition-transform">
                        <p className="text-center font-bold text-xl text-gray-700">Van {myName}</p>
                        <img src={myPicture} alt=" Als je dit leest is er iets foutgegaan." className="w-full h-auto rounded-md mb-2" />
                        <p className="text-s  text-gray-600 italic text-center">{myWish}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step4;
