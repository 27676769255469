import React from 'react';

interface errorMessageProps {
  message: string;
}


const ErrorMessage: React.FC<errorMessageProps> = ({message}) => {

  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative" role="alert">
    <strong className="font-bold">Oeps er ging iets mis!</strong>
    <br></br>
    <span className="block sm:inline break-word text-justify">Er is een probleem. Probeer het nog een keer, bel Edwin of Lotte of maak voor de zekerheid een screenshot. ({message} )</span>
</div>


  );
};

export default ErrorMessage;