import { initializeApp } from 'firebase/app';
import { initializeFirestore, collection, addDoc, serverTimestamp, FieldValue } from 'firebase/firestore'
import { getStorage, ref, uploadBytes  } from 'firebase/storage'; //
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ,
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ,
    appId: process.env.REACT_APP_FIREBASE_APP_ID ,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {})
export const storage = getStorage(app);

interface addDocumentProps {
    // onStepCompleted: () => void;
    collectionName: string;
    data: string;
}

export const putSelfieInStorage = async (snapshot:string) => {
    try {
        // Create a reference to the file in storage (you can customize the path as needed)
        const fileName = `webcam_snapshot_${Date.now()}.png`;
        const fileRef = ref(storage, `selfies/${fileName}`);

        // Convert the base64 snapshot to a Blob
        const blob = await fetch(snapshot).then((res) => res.blob());

        // Upload the file
        await uploadBytes(fileRef, blob);

        console.log('Snapshot uploaded successfully!');
    } catch (error) {
        console.error('Error uploading snapshot:', error);
        //alert('Er ging iets mis met het oplsaan van je selfie. Probeer het nog eens, en sla anders je selfie op met een screenshot.');
        return `Error: ${error}`;
    }
}



export const logEvent = async (eventName: string, eventValue: string) => {
    console.log(`logging event ${eventName} with value: ${eventValue}`);
    const analytics = getAnalytics(app);
    firebaseLogEvent(analytics, eventName, { param1: 'value1', param2: 'value2' });
};


export const addADocument = async ( collectionName: string, data: string ) => {
    //uncessary json parsing for now, since i'm not getting the json object passed in.
    type JsonObject = {
        // Define the structure of the parsed JSON object here
    };
    const parsedObject: JsonObject = JSON.parse(data);
    const collectionPath = localStorage.getItem('sessionId') || "";
    parsedObject['created_at'] = serverTimestamp();
    const handleAddDocument = async () => {
        try {
            // Reference to the top-level document
            const docRef = await addDoc(collection(db,  "/sessions/" + collectionPath + "/", collectionName), {
                ...parsedObject,
            });
            console.log("Document written with ID: ", docRef.id);
            return 'success';
        } catch (e) {
            console.error("Error adding document: ", e);
            return `Error: ${e}`;
            
        }
    };
    return handleAddDocument();
};

/* const getADocument = async () => {

    await getDocs(collection(db, "todos"))
        .then((querySnapshot) => {
            const newData = querySnapshot.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }));
            return newData;
            console.log("de data", newData);
        })

}*/