/*
TODO LIST
---------

Functionality
* error message weer wegdoen bij eerstvolgende succesvolle actie?

Security related
* Check if .env setup for both firebase and openAI is safe

nice to have
* step 2 json object parsing fixen
* grey out button for generating card fo 5 sec
* alignment van de confettie is off

*/
import React, { useState } from 'react';
import Step1 from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';
import { Confetti } from './confetti';
import Step4 from './step4';
import { addADocument, logEvent, putSelfieInStorage } from '../helpers/firebase';
import PrivacyPopup from './privacyPopup';
import { useGlobalContext } from './globalContext';
import { collectFormData } from '../helpers/helpers';
import * as htmlToImage from 'html-to-image';
import ErrorMessage from './errorMessage';
import { domToPng } from 'modern-screenshot'


const Home: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [confetti, setConfetti] = useState<boolean>(false);
    const [nextStepEnabled, setNextStepEnabled] = useState<boolean>(true);
    const [privacyPopupValue, setPrivacyPopupValue] = useState(false);
    //dit kan sowieso in 1 mooi object
    const { setMyWish } = useGlobalContext();
    const { setMyName } = useGlobalContext();
    const { setMyPicture } = useGlobalContext();
    const { myCardText } = useGlobalContext();
    const { errorMessage , setErrorMessage} = useGlobalContext();

    const handleNext = async () => {

        switch (currentStep) {
            case 1:
                console.log("Clicked step 1");
                onStep1Completed();
                break;
            case 2:
                //
                console.log("Clicked step 2");
                onStep2Completed();
                break;
            case 3:
                //
                console.log("Clicked step 3");
                onStep3Completed();
                break;
            case 4:
                //

                break;
            default:
                //
                ;
        }
        await new Promise((resolve) => setTimeout(resolve, 200));
        setCurrentStep((prevStep) => prevStep < 4 ? prevStep + 1 : prevStep);
        logEvent("ClickedNextStep", "step" + currentStep);
    };


    const handleBack = () => {
        setCurrentStep((prevStep) => prevStep > 1 ? prevStep - 1 : prevStep);
        console.log(currentStep);
        logEvent("ClickedPreviousStep", "step" + currentStep);
    };

    const handleDone = () => {
        setConfetti(true);
        logEvent("Clicked Done", "step" + currentStep);
    };

    const handlePrivacyButtonClick = () => {
        console.log("privacy open clicked");
        setPrivacyPopupValue(true);
    };

    const handlePrivacyPopupClose = () => {
        console.log("privacy closed clicked");
        setPrivacyPopupValue(false);
    };


    const onStep1Completed = () => {
        //not sure how to pass as JSONobject, therefore stryingyfying it
        //This function first gests all values from the form using a helper function and then writes those to firestore through a generic funciton that takes a collection name and a JSON object BUT STRINGYFIED
        interface JsonCardResponse {
            data: string;
        }
        //also super crappy since i'm not getting the json object passed in and thus fetching a string, putting it in a json record, to pass it along, and then making it a string again.
        const finalCardResponse: JsonCardResponse = { data: myCardText };
        addADocument( "birthCards", JSON.stringify(finalCardResponse) ).then((result) => {
            if (result.startsWith("Error")) {
                setErrorMessage((result));
            }
        })
    }

    const onStep2Completed = () => {
        //not sure how to pass as JSONobject, therefore stryingyfying it
        //This function first gests all values from the form using a helper function and then writes those to firestore through a generic funciton that takes a collection name and a JSON object BUT STRINGYFIED
        const step2form = document.getElementById('step2form') as HTMLFormElement;
        const formData = collectFormData(step2form);
        setMyWish(formData.wens);
        setMyName(formData.voornaam);
        addADocument( "respondents", JSON.stringify(formData) ).then((result) => {
            if (result.startsWith("Error")) {
                setErrorMessage((result));
            }
        })
    }
    

    const onStep3Completed = () => {
        //THis is very ugly, I rerender the whole image based on the html, since the lib can;t do it directly from the image src
        var node = document.getElementById('filteredImage');
        htmlToImage.toPng(node as HTMLElement)
            .then(function (dataUrl) {
                var img = new Image();
                img.src = dataUrl;
                setMyPicture(dataUrl);
                putSelfieInStorage(dataUrl);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                alert("Er is iets misgegaan bij het opslaan van je foto. Probeer het later nog eens.")
            });

            const filteredImageElement = document.querySelector('#filteredImage');
            if (filteredImageElement) {
                domToPng(filteredImageElement).then(dataUrl => {
                    var img = new Image();
                    img.src = dataUrl;
                    setMyPicture(dataUrl);
                    putSelfieInStorage(dataUrl);
                    const link = document.createElement('a')
                    link.download = 'screenshot.png'
                    link.href = dataUrl
                    link.click()    
                });
            } else {
                // Handle case when element is not found
            }
    }

    return (
        <div className=" relative w-6/6 max-w-md ">
            <div className="flex flex-col justify-center">
                {errorMessage != "" && (
                    <ErrorMessage message={errorMessage} />
                )}
            </div>
            <div className=" mt-2 p-4 border border-gray-300 shadow-md bg-backgroundcolor ">
                <div className="mb-4">
                    <StepComponent step={currentStep} onStepCompleted={handleNext} />
                </div>
                <div className="flex mt-6 justify-between">
                    {currentStep === 1 ? (

                        <button
                            className=" text-slate-50  px-3 py-2 rounded  font-semibold sm:w-auto focus:outline-none bg-slate-200"
                            onClick={handleBack}
                        >
                            Terug
                        </button>
                    ) : (
                        <button
                            className="text-black px-3 py-2 rounded  font-semibold  shadow-sm hover:bg-secondary  sm:w-auto focus:outline-none bg-primary"
                            onClick={handleBack}
                        >
                            Terug
                        </button>
                    )}
                    {(currentStep < 4) ? (
                        (nextStepEnabled) ? (
                            <button
                                className=" text-black px-3 py-2 rounded  font-semibold  shadow-sm hover:bg-secondary sm:w-auto focus:outline-none bg-primary"
                                onClick={handleNext}
                            >
                                Volgende
                            </button>
                        ) : (
                            <button
                                className=" text-slate-50  px-3 py-2 rounded  font-semibold sm:w-auto focus:outline-none bg-slate-200"
                            >
                                Volgende
                            </button>
                        )

                    ) : (
                        <button
                            className=" text-black px-3 py-2 rounded  font-semibold  shadow-sm hover:bg-secondary sm:w-auto focus:outline-none bg-primary"
                            onClick={handleDone}
                        >
                            Klaar!
                        </button>
                    )}

                </div>
                {confetti && (
                    <Confetti />
                )}
            </div>
            <div className="flex flex-col justify-center">
                <button onClick={handlePrivacyButtonClick}
                    className="text-sm py-2 w-full text-gray-600">
                    En mijn privacy?<span aria-hidden="true"></span>
                </button>
                {privacyPopupValue && (
                    <PrivacyPopup onClickHandler={handlePrivacyPopupClose} />
                )}
            </div>
        </div>

    );
};


interface StepComponentProps {
    step: number;
    onStepCompleted: () => void;
}

const StepComponent: React.FC<StepComponentProps> = ({ step, onStepCompleted }) => {
    let content;

    switch (step) {
        case 1:
            content = (<Step1 onStepCompleted={onStepCompleted} />);
            break;
        case 2:
            content = (<Step2 onStepCompleted={onStepCompleted} />);

            break;
        case 3:
            content = (<Step3 onStepCompleted={onStepCompleted} />);

            break;
        case 4:
            content = (<Step4 onStepCompleted={onStepCompleted} />);

            break;
        default:
            content = (
                <div>
                    <h2>Invalid Step</h2>
                    <p>Invalid step number</p>
                </div>
            );
    }

    return (
        <div>
            {content}
        </div>
    );
};



export default Home;