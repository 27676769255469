import React, { useEffect, useState } from 'react'
import Confetti2 from 'react-confetti'


export const Confetti: React.FC = () => {

    const [screenSize, setScreenSize] = useState(getWindowDimensions());

function getWindowDimensions() {
    const { outerWidth: width, outerHeight: height } = window;
    return {
      width,
      height
    };
  }


  
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getWindowDimensions())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  return (
    <Confetti2
      width={getWindowDimensions().width}
      height={getWindowDimensions().height}
    numberOfPieces={100}
    colors={["#facc15","#fdba74","#FBF6EE","#65B741","#C1F2B0"]}
    wind={ 0.01}

    />
  )
}