interface FormData {
    [key: string]: string;
  }
  

  /* dit kan heel mooi met een json doc */
export class BabySettings {
    public static babyName = 'Nura';
    public static babyDatum = '25 december 2023';
    public static babyTime = '2:30 AM';
    public static babyGender = 'Meisje';
}

export function collectFormData(form: HTMLFormElement): FormData {
    const formData: FormData = {};

    // Iterate over form elements
    for (const element of Array.from(form.elements) as HTMLInputElement[]) {
        // Check if the element has a name and a value
        if (element.name && element.value) {
            // Assign the value to the corresponding key in the formData object
            formData[element.name] = element.value;
        }
    }

    return formData;
}
