import React from 'react';
import { createContext, useContext, FC, ReactNode } from 'react';

interface GlobalContextProps {
  // Define the type of your global state here
  myWish: string;
  setMyWish: (value: string) => void;
  myName: string;
  setMyName: (value: string) => void;
  myPicture: string;
  setMyPicture: (value: string) => void;
  myCardText: string;
  setMyCardText: (value: string) => void;
  errorMessage: string;
  setErrorMessage: (value: string) => void;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [myWish, setMyWish] = React.useState<string>('');
  const [myName, setMyName] = React.useState<string>('');
  const [myPicture, setMyPicture] = React.useState<string>('');
  const [myCardText, setMyCardText] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const contextValue: GlobalContextProps = {
    myWish,
    setMyWish,
    myName,
    setMyName,
    myPicture,
    setMyPicture,
    myCardText,
    setMyCardText,
    errorMessage,
    setErrorMessage,
  };

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};