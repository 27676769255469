

import React, { useState } from 'react';
import logo from '../zebra.png';
import { BirthCard } from './birthCard';
import { useGlobalContext } from './globalContext';
import { BabySettings } from '../helpers/helpers';
import { FetchGPTResponse } from '../helpers/chatGPT';


interface Step1Props {
  onStepCompleted: () => void;
}

export const Step1: React.FC<Step1Props> = ({ onStepCompleted }) => {
  const { myCardText, setMyCardText } = useGlobalContext();
  const { errorMessage, setErrorMessage } = useGlobalContext();


  const [loadBirthCard, setLoadBirthCard] = useState(false);
  const [chatVoiceValue, setChatVoiceValue] = useState("Leuke tante");
  const [useEmoij, setUseEmoij] = useState(false);
  const [respsonseLength, setResponseLength] = useState(250);


  const handleLStartButtonClick = () => {
    console.log(`clicked on maak kaartje button with voice: ${chatVoiceValue} and useEmoij: ${useEmoij} and responseLength: ${respsonseLength} `);
    setLoadBirthCard(true);
    setMyCardText("");
    FetchGPTResponse(chatVoiceValue, useEmoij, respsonseLength).then((result) => {
     // better to handle this error from the fetchGPTResponse function is to use some sort of tuple object, now it is a bit messy.
      if(result.startsWith("Error:")){
        setErrorMessage(result);
      }else{
        setMyCardText(result);
      }
  
    });
  
  };


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setChatVoiceValue(value);
    return console.log(`You selected ${value} and it stored ${chatVoiceValue}`);
  };

  const handleUseEmoijChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseEmoij(event.target.checked);
    return console.log(`You selected using emoijs: ${useEmoij}`);
  };

  const handleLengthSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    console.log(`You selected length: ${newValue}`);
    setResponseLength(newValue);
  };


  return (
    <div className="App">
      <img src={logo} className="h-auto max-w-full" alt="logo" />
      <div className="text-center">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Hoi, ik ben {BabySettings.babyName}
        </h1>
        <p className="text-s my-4 text-gray-600">Do it yourself! Maak hier zelf mijn geboortekaartje! Kies een personage en probeer het zo vaak als je wilt!</p>
        <div className="grid grid-cols-2 gap-4 mt-4 content-start">
          <div className=" relative w-84">
            <select value={chatVoiceValue} onChange={handleSelectChange} className="block text-sm appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
              <option>Blije tante</option>
              <option>Limburger</option>
              <option>Standup comedian</option>
              <option>Straattaal jochie</option>
              <option>Marsmannetje</option>
              <option>Brabander</option>
              <option>Suikeroom</option>
              <option>Wiskundige nerd</option>
              <option>Hipster barista</option>
              <option>Spirituele Yogaleraar</option>
              <option>Theatrale Shakespeare-liefhebber</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
            </div>
          </div>

          <button onClick={(e) => handleLStartButtonClick()}
            className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Maak kaartje!
          </button>
        </div>
      </div>
      <div className="flex my-4 items-center space-x-4">
        <label className="flex items-center space-x-2">
          <input type="checkbox" checked={useEmoij} className="form-checkbox accent-primary" onChange={handleUseEmoijChange} id="useEmoij" />
          <span className="text-gray-600 text-xs">Emoji gebruiken</span>
        </label>
        <input
          type="range"
          min="0"
          value={respsonseLength}
          max="700"
          step="25"
          onChange={handleLengthSliderChange}
          className="w-20 appearance-none bg-slate-200 h-3 rounded-full accent-primary outline-none "
        />
        <span className="text-gray-600 text-xs">Lengte tekst</span>
      </div>

      {loadBirthCard ? (<div><BirthCard text={myCardText}  />
        </div>
      )
        : (
          <div></div>
        )}

    </div>
  );
}

export default Step1;
