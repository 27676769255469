import React, { useEffect } from 'react';
import OpenAI from 'openai';
import { logEvent } from '../helpers/firebase';
import { useGlobalContext } from '../components/globalContext';
import { BabySettings } from './helpers';

interface JsonObject {
    naam: string,
    geslacht: string
    geboortedatum: string,
    geboortetijd: string,
    gewicht: string,
    kleurOgen: string,
    kleurHaar: string,
    geboorteplaats: string,
    geboorteZiekenhuis: string,
    geboorteland: string,
    ouders: string,
    bijzonderheden: string,
    gezichtsuitdrukking: String,
    voorkomen: string,
    bezoekdetails: string,
}

const babyData: JsonObject = {
    naam: BabySettings.babyName,
    geslacht: BabySettings.babyGender,
    geboortedatum: BabySettings.babyDatum,
    geboortetijd: BabySettings.babyTime,
    gewicht: '2940 gram',
    kleurOgen: 'donkerblauw',
    kleurHaar: 'bruin',
    geboorteplaats: 'Utrecht',
    geboorteZiekenhuis: 'Diakonnessenhuis',
    geboorteland: 'Nederland',
    ouders: 'Edwin en Lotte',
    voorkomen: 'gezond, maar een klein beetje te klein.',
    bijzonderheden: 'geboren op eerste kerstdag. Haar naam betekent licht.',
    gezichtsuitdrukking: 'een beetje moeilijk, maar ook wel lief',
    bezoekdetails: 'Bezoek is elke dag welkom, graag even een berichtje om een moment te plannen.',
}

export const FetchGPTResponse = async (voice: string, useEmoij: boolean,responseLength: number)  => {
    const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
    });

        try {
            console.log('OpenAI API: Starting query with voice: ' + voice + ' and useEmoij: ' + useEmoij + ' and responseLength: ' + responseLength + '');
            const res = await openai.chat.completions.create({
                //model: 'gpt-4-1106-preview',
                model: 'gpt-3.5-turbo-1106',
                temperature: 0.8,
                //response_format: { type: "json_object" },
                messages: [
                    {
                        role: 'system', content: `Je gaat me op basis van de JSON gegevens over een pasgeboren baby een kort geboortekaart tekstje schrijven. Dit kaartje is vanuit de ouders gericht aan de lezer van dit kaartje.  Je doet dit met de uitvergrote persoonlijkheid van een ${voice}. Gebruik daarin de taal van deze persoonlijkheid. `
                    },
                    {
                        role: 'user', content: `Dit is de informatie die je krijgt: ${JSON.stringify(babyData)}. Maak een geboortekaartje dat je opbouwt met een titelzin, een hoofdtekst en een korte wens voor op het kaartje. Sluit altijd af met de bezoekdetails. Benoem deze elementen niet letterlijk. Je reactie moet ongeveer ${responseLength} woorden lang zijn. Maak een grapje.  ${useEmoij ? 'Gebruik veel emoji' : ''} `
                    },
                ],
            });
            const result = res.choices[0]?.message.content || '';
            console.log('OpenAI API: Finished query');
            logEvent("generatedCard", result);
            return result;

        } catch (error) {
            console.error('Error:', error);
            return `Error: ${error}`;
        }
};

